
#remove-image-btn {
    border: 1px solid;
    border-radius: 5px;
    margin: 0.3em;
    padding: 0.3em;
    text-align: center; 
    background-color: var(--feel-color);
    color: white;
    font-weight: bold;
    cursor: pointer;
}

#remove-image-btn.lbl.disabled {
    background: #ffa0a0; /* Change this to the desired background color */
    cursor: not-allowed;
}

.gallery-image-name {
    border: 1px solid;
    border-radius: 5px;
    margin: 0.3em;
    padding: 0.3em;
    text-align: center;
    cursor: pointer;
}

.gallery-image-name.selected {
    background-color: var(--color-title);
    color: white;
}

#modifyAddress {
    width: 25px;
}

.lbl {
    text-align: right;
    /* width: 100px; */
    white-space: nowrap;
}

.fld {
    width: 100%;
}

.frm {
    display: inline-block;
}

fieldset {
    border-radius: 10px;
    margin-bottom: 2em;
}

legend {
    font-size: 1.2rem;
    font-weight: bold;
}


.create-box {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3em;
}

.lista-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}       

.item {
    border-radius: 10px;
    max-width: 95%;
    height: auto; 
    max-height: 600px;
    margin: 0 5px; 
}

.sliderimg{
    margin-top: 2em;
    max-width: 40%;
}

.sliderdiv{
    display: flex !important;
    justify-content: center;
}

.case {
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: -1rem;
    padding-left: 0rem;
    text-align: center;
}

.case a{
    font-size: 1.1rem;
}

.feel{
    color: var(--feel-color);
}
.feel::selection {
    color: var(--feel-sel-color);
    background: var(--selection-b-color);
}

.feel::-moz-selection {
    color: var(--feel-sel-color);
    background: var(--selection-b-color);
  }

.address{
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2rem;
}

.lista-title {
    font-size: 3rem;
    align-items: center;
    margin: 0px;
}

.lista-subtitle {
    font-size: 2rem;
    align-items: center;
    margin: 0px;
}


@media screen and (max-width: 820px) {
    .create-box {
        width: 100%;
    }

    .text-for-gallery{
        display: inherit;
    }

    .arrow{
        display: none !important;
    }

    .sliderimg{
        max-width: 60%;
        /* height: auto; */
    }

    .item {
        max-width: 95%;
        max-height: 1000px
    }

    .lista-title {
        font-size: 2rem;
    }

    .container-lista img {
        max-width: 60%;
    }

    .curtain {
        width: 45%;
        transition: transform 3s ease-out;
    }

    .left-curtain {
        right: 40%;
    }

    .right-curtain {
        left: 40%;
      }

  }

@media screen and (max-width: 500px) {
    .create-box {
        width: 100%;
    }

    .case {
        max-width: 85%;
    }

    .text-for-gallery{
        display: inherit;
    }

    .arrow{
        display: none !important;
    }

    .sliderimg{
        max-width: 85%;
        /* height: auto; */
    }

    .item {
        max-width: 95%;
        max-height: 1000px
    }

    .container-lista {
        width: 20em;
        height: 27em;
    }

    body[data-theme="dark"] .container-lista {
        width: 20em;
        height: 25em;
    }

    .lista-title {
        font-size: 2rem;
    }

    .container-lista img {
        max-width: 80%;
    }

    .curtain {
        width: 45%;
        transition: transform 3s ease-out;
    }

    .left-curtain {
        right: 60%;
    }

    .right-curtain {
        left: 60%;
      }


  }