.footer-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* margin-bottom: 0.4em; */
}

.footer-row {
    font-size: 1.1rem;
    padding: 1em;
    display: flex;
    grid-gap: 5vw;
    width: 100%;

}

.footer-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.footer-heading {
    font-size: 24px;
    margin-bottom: 0.3em;
    font-weight: bold;
}

.footer-case{
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 8px; 
    vertical-align: middle;
    font-size: 0.8rem;
    color: var(--color-text);
    margin-top: 3em;
}

.footer-link{
    color: var(--color-text);
    margin-bottom: 0.5em;
    font-weight:400;
    text-decoration: none;
}

.box{
    display: flex;
    left: 0;
    bottom: 0;
    padding-top: 2.5%;
    padding-bottom: 1%;
    background: var(--color-footer);
    /* position: absolute; */
    width: 100%;
 
    @media (max-width: 1000px) {
        /* padding: 70px 30px; */
    }
}

@media screen and (max-width: 850px) {
    .footer-div{
        font-size: 1.3rem;
    }
    .footer-case{
        font-size: 1.1rem;
    }

}

@media screen and (max-width: 500px) {
    .footer-div{
        font-size: 1.2rem;
    }
    .footer-case{
        font-size: 0.9rem;
    }

}