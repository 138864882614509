.header-nav{
    display: flex;
    justify-content: right;
    align-items: center; 
    gap: 1em; 
    vertical-align: middle;
    font-size: 1.5rem;
    color: var(--color-text);
    /* margin-bottom: 0.4em; */
    padding-right: 2vw;
    /* margin-right: 16px; */
    /* padding-top: 3em; */
}

.header-logo{
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center; 
  gap: 16px; 
  vertical-align: middle;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-text);
  padding-left: 2vw;
}

.header-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0%;
    height: 100px;
    width: 100%;
    z-index: 1001;
    background-color: var(--color-background);
    transition: background-color 1s ease, color 1s ease;
 
    @media (max-width: 1000px) {
        /* padding: 70px 30px; */
    }
}

/* Base styles for the dropdown button */
.dropdown .dropbtn {
    background: none;
    font: inherit;
    border: none;
    color: var(--text-color);
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Hiding the dropdown content by default */
  .dropdown-content {
    display: none;
    position: absolute; 
    border-radius: 5px;
    background-color: var(--color-background);
  }
  
  /* Displaying the dropdown content when hovering over the dropdown button */
  .dropdown:hover .dropdown-content,
  .dropdown .dropbtn:focus + .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 10px;
    text-decoration: none; /* To remove underline from links */
    display: block; /* To make each link take the full width */
  }
  
  .dropdown-content a:hover {
    background-color: var(--selection-b-color);
    color: var(--selection-color) !important;
  }

  .button-dropdown:hover {
    background-color: var(--feel-color);
    color: var(--selection-color) !important;
  }
  
  .nav-link.active {
    color: var(--text-color);
    font-weight: bold;
    cursor: pointer;
  }

  .button-dropdown {
    background: none;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 10px;
    font: inherit;
    border: none;
    color: var(--text-color);
    font-weight: bold;
    cursor: pointer;
    display: block;
  }
  

@media screen and (max-width: 850px) {


}

@media screen and (max-width: 550px) {
  #home {
    display: none;
  }
  .header-logo,
  .header-nav{
    font-size: 1.1rem;
    gap: 0.1em;
  }


}