*, html, body {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }

.row::after {
    clear: both;
    content: "";
    display: table;
}

.listings-container {
  padding: 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3em;
}

.my-col{
  flex: 0 0 21%;  /* Each column takes up one-third of the container */
  max-width: 21%;
  margin: 0.2em;
}

.my-col-9 {
  max-width: 50%;
  margin: 0.2em;
  margin-bottom: 2em;
}


@media screen and (max-width: 1500px) {
  .my-col, .my-col-9 {
    flex: 0 0 50%;   /* Below 768px, each column takes full width */
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .my-col, .my-col-9 {
    flex: 0 0 100%;   /* Below 768px, each column takes full width */
    max-width: 100%;
  }
}

#content-area {
    overflow: auto;
    width: 100%;
    min-height: 100vh;
  }
#listings {
width: 100%;
/* float: left; */
display: inline-block;
padding: 15px;
}
#listings .search-area input[type="text"] {
width: 70%;
background: transparent;
font-size: 2rem;
border: none;
border-bottom: 1px solid #828d9d;
margin-bottom: 20px;
font-weight: 300;
color: #c8c8c8;
}
#listings .sortby-area {
margin-bottom: 20px;
}
#listings .sortby-area .results {
color: #828d9d;
font-size: 0.8rem;
display: inline-block;
}
#listings .sortby-area .sort-options {
float: right;
}
#listings .sortby-area .sort-options select {
color: #828d9d;
font-size: 0.9rem;
font-weight: 300;
border: 1px solid #c8c8c8;
background: transparent;
padding: 20px;
display: inline-block;
margin-right: 15px;
vertical-align: top;
}
#listings .sortby-area .sort-options .view {
display: inline-block;
}
#listings .sortby-area .sort-options .view .fa {
margin-right: 15px;
font-size: 1.5rem;
color: darkgrey;
}

#listings .listings-results .listing {
background: #fff;
border-radius: 5px;
/* margin-bottom: 60px; */
-webkit-box-shadow: 0px 9px 54px -4px var(--color-footer);
-moz-box-shadow: 0px 9px 54px -4px var(--color-footer);
box-shadow: 0px 9px 54px -4px var(--color-footer);
}
#listings .listings-results .listing-img {
color: #fff;
padding: 15px;
position: relative;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
min-height: 15em;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}

#listings .listings-results .listing-img:hover .details {
opacity: 1;
}
#listings .listings-results .listing-img:hover .address {
opacity: 0;
}
#listings .listings-results .listing-img .address {
color: var(--selection-color);
font-size: 1rem;
text-transform: capitalize;
position: absolute;
bottom: 3%;
opacity: 1;
transition: all 0.4s ease-in-out;
/* text-shadow: 0px 1px 4px rgba(91, 91, 91, 1); */
background-color:var(--selection-b-color);
padding: 5px 10px;
border-radius: 5px;
}
#listings .listings-results .listing-img .details {
transition: all 0.4s ease-in-out;
opacity: 0;
padding: 5px;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-moz-border-radius-topright: 5px;
border-radius: 5px;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7711f4+0,c204f2+100 */
background: rgba(36, 35, 36, 0.8);
/* Old browsers */
background: -moz-linear-gradient(-45deg, rgba(36, 35, 36, 0.8), rgba(224, 222, 227, 0.8) 100%);
/* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgba(36, 35, 36, 0.8), rgba(224, 222, 227, 0.8) 100%);
/* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgba(36, 35, 36, 0.8), rgba(224, 222, 227, 0.8) 100%);
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7711f4', endColorstr='#c204f2',GradientType=1);
/* IE6-9 fallback on horizontal gradient */
}
#listings .listings-results .listing-img .details .user-img-box {
width: 35px;
height: 35px;
border-radius: 50%;
border: 2px solid #fff;
display: inline-block;
}
#listings .listings-results .listing-img .details .user-img-long {
width: 35px;
height: 35px;
border-radius: 50%;
border: 2px solid #fff;
display: inline-block;
margin-left: 30%;
}
#listings .listings-results .listing-img .details .user-details {
vertical-align: top;
display: inline-block;
height: 20px;
}
#listings .listings-results .listing-img .details .user-details .user-name {
font-weight: 300;
font-size: 0.9rem;
display: block;
color: rgba(255, 255, 255, 1);
margin-top: 3px;
margin-bottom: 5px;
}
#listings .listings-results .listing-img .details .listing-details {
/* margin-top: 30px; */
margin-bottom: 10px;
}
#listings .listings-results .listing-img .details .listing-details .fa {
display: inline-block;
font-size: 18px;
margin-right: 15px;
}
#listings .listings-results .listing-img .details .listing-details span {
display: inline-block;
font-weight: 300;
font-size: 0.8rem;
vertical-align: top;
position: relative;
top: 3px;
}
#listings .listings-results .listing-img .details .listing-details .floor-space {
  border-radius: 5px;
  padding: 3%;
  background-color: var(--selection-color);
  color: var(--selection-b-color);
  margin: .5em;
  font-size: 1.1rem;
}

.dettagli-button, 
.add-button {
  background-color: var(--color-footer);
  border-radius: 10px;
  padding: 3%;
  color: var(--selection-b-color);
  margin-bottom: 1px;
  font-size: 1.1rem;
  box-shadow: 0 2px var(--selection-b-color);
}

.dettagli-button:hover {
  background-color: var(--submit-color);
  transition: 200ms ease-in;
}

.add-button:hover {
  background-color: var(--a-color);
  color: var(--selection-color);
  transition: 200ms ease-in;
}

.delete-button {
  background-color: var(--color-footer);
  border-radius: 10px;
  padding: 3%;
  color: var(--selection-b-color);
  margin-bottom: 1px;
  font-size: 1.1rem;
  font-weight: 400;
  box-shadow: 0 2px var(--selection-b-color);
}

.delete-button:hover {
  color: var(--selection-color);
  background-color: var(--feel-color);
  transition: 200ms ease-in;
}

#listings .listings-results .post-date {
font-size: 0.6rem;
display: block;
color: rgba(255, 255, 255, .7);
padding-top: 10px;
}
#listings .listings-results .bottom-info {
  position: inherit;
  padding: 20px 15px;
}
#listings .listings-results .bottom-info .price {
font-size: 1rem;
display: block;
margin-bottom: 10px;
color: #828d9d;
}
#listings .listings-results .bottom-info .location {
font-size: 1rem;
display: block;
color: #c8c8c8;
}
.view-btn {
background: #fffeff;
color: #828d9d;
padding: 8px;
font-size: 0.9rem;
border-radius: 5px;
text-align: center;
max-width: 150px;
transition: background 0.3s ease-in-out;
}
.view-btn:hover {
background: #021847;
color: #fff;
cursor: pointer;
}
#pagination {
width: 100%;
display: block;
margin-top: 70px;
}
#pagination .pages {
margin: 0 auto;
display: inline-block;
background: #fff;
-webkit-box-shadow: 0px 9px 54px -4px rgba(224, 224, 224, 1);
-moz-box-shadow: 0px 9px 54px -4px rgba(224, 224, 224, 1);
box-shadow: 0px 9px 54px -4px rgba(224, 224, 224, 1);
}
#pagination .pages li {
padding: 20px 30px;
display: inline-block;
}
#pagination .pages li.active, #pagination .pages li:hover {
color: #fff;
font-weight: 700;
background: #639;
cursor: pointer;
}


.spinner {
  margin: 100px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--selection-b-color);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}