#editButton:hover {
  background-color: var(--color-title);
  color: white;
  transition: 200ms ease-in;
}

.parent-container {
  padding: 3em;
  display: flex;
  flex-direction: row;
  gap: 5em;
  min-height: 60vh;
}

.main-gallery {
  flex: 1;
  max-width: 70%;
}
.main-gallery img{
border-radius: 10px;
max-width: 50vw;
max-height: 60vh;
}

.slick-list {
  width: 70%;
}

.slick-prev::before, .slick-next::before {
  color: var(--color-text)!important;
  font-size: 1.8rem!important;
}

.thumb-gallery .slick-prev::before, 
.thumb-gallery .slick-next::before {
    display: none;
}

.thumb-gallery {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  gap: 1em;
  width: 40%;
}

.thumb-gallery img{
  border-radius: 5px;
  width: 80%;
  }

.post-page-container {
  flex: 1;
  /* width: 30%; */
}

.pac-logo::after {
  background-image: none;
  background-color: none;
  border-radius: 10px;
}

.pac-item{
  font-family: 'Josefin Sans', sans-serif;
}

.leaflet-control-attribution{
  display: none;
}

.map-immobile {
  flex: 2;
  margin-top: 5em;
  /* width: 40%; */
  overflow: 'hidden';
}

.leaflet-container {
  border-radius: 15px;
}


.download-btn {
  background-image: url('../../assets/download-light.png');
  background-size: cover;
  display: block;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  color: var(--color-text);
  margin-right: 0.5em;
  border-radius: 10px;
  text-decoration: none;
  transition: 200ms ease-in;
}

.download-btn:hover {
  color: var(--ref-color);
  border: 1px solid var(--ref-color);
  transition: 200ms ease-in;
}

body[data-theme="dark"] .download-btn {
  background-image: url('../../assets/download-dark.png'); 
}


.video-background {
    position: relative;
    padding-bottom: 46%;
    width: 100vw;
    overflow: hidden;
  }
  
  .video-foreground,
  .video-foreground iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50vw;
    height: 50vh;
    transform: translate(-50%, -50%) scale(1.5); 
    pointer-events: none;
  }
/* Add more specific styles for other elements like lists, blockquotes, code, etc. */

  @media screen and (max-width: 1250px) {
    .thumb-gallery {
      width: 50%;
    }
    .main-gallery {
      max-width: 85%;
    }

  }

  @media screen and (max-width: 850px) {
  .parent-container {
    padding: 2em;
    display:block;
  }
  .slick-prev::before, .slick-next::before {
    display: none;
  }

  .main-gallery {
    max-width: 100%;
  }

  .main-gallery img{
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    }

  .thumb-gallery {
    width: 100%;
  }

  .thumb-gallery img{
    border-radius: 5px;
    width: 60%;
    }

  .slick-list {
    width: 90%;
  }

  .map-immobile {
    /* flex: 2; */
    height: 30em;
    width: 100%;
    overflow: 'hidden';
  }
}