input,
textarea,
button {
    background-color: var(--color-footer);
    color: var(--color-text);
}

#submit:hover{
    background-color: var(--submit-color);
    transition: 200ms ease-in;
}

.success-message {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--a-color);
    width: 30rem;

  }
  .error-message {
    font-weight: bold;
    font-size: 1rem;
    color: rgb(255, 78, 78);
    transition: color 200ms ease-in;
    width: 30rem;
  }  

.message-title {
    font-size: 3rem;
    align-items: center;
    margin: 0px;
}


.message-h2 {
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: -1rem;
    padding-left: 1rem;
    text-align: center;
  }

.message-section{
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: black; */
    flex-direction: column;
    /* height: 100vh; */
    margin-bottom: 5em;
    
}   

.message {
    font-size: 3rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

input {
    text-align: center;
    font-weight: 500;
    height: 3.5rem;
    font-size: 1.2rem;
    width: 30rem;
    border-radius: 5px;
    border: 1;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* color: #3b3838; */
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

textarea {
    font-size: 1.2rem;
    height: 9rem;
    width: 30rem;
    border: 1;
    border-radius: 5px;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* color: #3b3838; */
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

::placeholder {

    text-align: center;
    font-size: 1.2rem;
    color: var(--color-text);
  }

  /* Added This */
.op-class{  
    opacity: 0;
}

.fadeIn {
    opacity: 1;
    transition: opacity 3s ;
}


  @media screen and (max-width: 500px) {

    .message {
        /* margin: 0; */
        font-size: 2rem;

    }
    
    input {
        width: 90vw;
    }

    textarea {
        width: 90vw;
    }

    .message-title {
        font-size: 2rem;
    }

    .message-section{
        margin-bottom: 2em;
        
    }   

  }
